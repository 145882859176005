import _ from "lodash";

const bracketRegex = /[\[\]]+/g

export default {
    subtractYears(numOfYears, date = new Date()) {
        const dateCopy = new Date(date.getTime());

        dateCopy.setFullYear(dateCopy.getFullYear() - numOfYears);

        return dateCopy;
    },
    getFormattedDate(dateString) {
        let date = new Date(dateString);
        let year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : "0" + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : "0" + day;

        return month + "/" + day + "/" + year;
    },
    async copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        await navigator.clipboard.writeText(text).then(
            function () {
                console.log("Async: Copying to clipboard was successful!");
            },
            function (err) {
                console.error("Async: Could not copy text: ", err);
            }
        );
    },
    fallbackCopyTextToClipboard(text) {
        let textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            let successful = document.execCommand("copy");
            let msg = successful ? "successful" : "unsuccessful";
            console.log("Fallback: Copying text command was " + msg);
        } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
        }

        document.body.removeChild(textArea);
    },
    fetchPhones(scanResult) {
        let phonesSet = new Set();
        if (scanResult.crawlReport && scanResult.crawlReport.phones) {
            scanResult.crawlReport.phones.map((phone) => phonesSet.add({
                number: phone.replace(/\D/g, ''),
                source: 'web'
            }));
        }

        if (scanResult.whoisRecord && scanResult.whoisRecord.contacts) {
            let contacts = scanResult.whoisRecord.contacts;
            contacts.filter(c => c !== null && c.phone).map((contact) => phonesSet.add({
                number: contact.phone.replace(/\D/g, ''),
                source: 'whois'
            }));
        }

        if (scanResult.facebookInfo && scanResult.facebookInfo.phone) {
            phonesSet.add({number: scanResult.facebookInfo.phone, source: 'fb'});
        }

        return Array.from(phonesSet);

    },
    fetchEmails(scanResult) {
        let emailSet = new Set();

        if (scanResult.whoisRecord && scanResult.whoisRecord.contacts) {
            let contacts = scanResult.whoisRecord.contacts;
            contacts.filter(c => c !== null && /.*@.*/g.test(c.email)).map((contact) => emailSet.add({
                email: contact.email,
                source: 'whois'
            }));
        }
        if (scanResult.crawlReport && scanResult.crawlReport.emails) {
            scanResult.crawlReport.emails.map((email) => emailSet.add({email, source: 'web'}));
        }
        if (scanResult.facebookInfo && scanResult.facebookInfo.email) {
            emailSet.add({email: scanResult.facebookInfo.email, source: 'fb'});
        }

        return Array.from(emailSet);
    },
    getAverageLoadDuration(scanResult) {
        const crawledPages = scanResult.crawlReport?.crawledPages;
        return crawledPages ? Math.floor(crawledPages.reduce((sum, cur) => sum + cur.loadDuration, 0) / crawledPages.length) : null;
    },
    fetchA(scanResult) {
        return scanResult.dnsReport ? scanResult.dnsReport.a : "";
    },
    getCreation(scanResult) {
        return (scanResult.whoisRecord && scanResult.whoisRecord.creationDate) ? scanResult.whoisRecord.creationDate : null;
    },
    fetchMX(scanResult) {
        return scanResult.dnsReport ? scanResult.dnsReport.mx[0] : "";
    },
    fetchNS(scanResult) {
        return scanResult.dnsReport ? scanResult.dnsReport.ns[0] : "";
    },

    fetchTitle(scanResult) {
        function longestTitle(crawlReport) {
            const titles =
                crawlReport.crawledPages.map(
                    page => {
                        return page.title;
                    }
                ).filter(title => title)

            return titles.reduce((max, title) => {
                return title.length > max.length ? title : max
            }, titles[0]);
        }

        return scanResult.crawlReport && scanResult.crawlReport.crawledPages
        && scanResult.crawlReport.crawledPages.length > 0 ? longestTitle(scanResult.crawlReport) : '';
    },

    fetchCountry(scanResult) {
        return scanResult.dnsReport ? scanResult.dnsReport.countryCode : "";
    },

    sortedMessages(conversation) {
        return conversation ? _.orderBy(conversation.messages, "dateCreated", "asc") : [];
    },

    sortedMessagesDesc(conversation) {
        return conversation ? _.orderBy(conversation.messages, "dateCreated", "desc") : [];
    },
    checkMessageDTO(dto) {
        let message = {};
        Object.assign(message, dto);

        message.conversationId = dto.conversationId ? dto.conversationId : dto.conversation.id;
        return message;
    },
    filterOut(data, filter) {
        console.log("Filtering data", data, filter)

        let result = Object.keys(data).reduce((newObj, key) => {
            const value = data[key];
            if (value != filter) {
                newObj[key] = value;
            }
            return newObj;
        }, {});

        console.log("Received result", result)
        return result;
    },


    parseEmails(emailString) {
        if (emailString) {
            let emails = emailString.split(', ');
            emails = emails.map(email => email.replaceAll(bracketRegex, ''))
            return emails;
        }
        return emailString;
    }
};
